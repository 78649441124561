import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import '../../styles/Login.css'
import axios from 'axios';
const BACKEND = process.env.REACT_APP_API_URL;

const LOGIN_URL = `${BACKEND}/auth`;

const Login = ({ rideApp }) => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const errRef = useRef();

    const [user, resetUser, /*userAttribs*/] = useInput('user', '')
    const [email, setEmail] = useState('');
    let trimmedEmail = email.trim().toLowerCase();
    const [pwd, setPwd] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd, trimmedEmail }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                    ,
                    timeout: 7000 // Timeout after 7 seconds
                }
            );
            const accessToken = response?.data?.accessToken;
            const userId = response?.data?.id;
            const username = response?.data?.username
            const isAdmin = response?.data?.isAdmin
            const isSuperAdmin = response?.data?.isSuperAdmin
            const profilePicture = response?.data?.profile_picture
            // const roles = response?.data?.roles;


            setAuth({
                userId,
                username,
                email,
                // roles, 
                accessToken,
                isAdmin,
                isSuperAdmin,
                profilePicture
            });
            resetUser();
            // resetEmail();
            setPwd('');
            rideApp ? (navigate('../rides/public')) : (navigate('../runs/public'))

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
                navigate('/');
            } else if (err.response?.status === 400) {
                setErrMsg('Username or password are wrong or missing');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <div className='section-classname'>
            <section >
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div className="level-title">Sign In</div>
                <form onSubmit={handleSubmit}>
                    <label className="login-label" htmlFor="email">Email:</label>
                    <input
                        className='login-input'
                        type="text"
                        id="email"
                        ref={userRef}
                        autoComplete="off"
                        // {...emailAttribs}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    // required
                    />

                    <label className="login-label" htmlFor="password">Password:</label>
                    <input
                        className='login-input'
                        type="password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                    />
                    <button className='login-button'>Sign In</button>
                    <div className="persistCheck">
                        <input
                            className='login-input no-margin-bottom'
                            type="checkbox"
                            id="persist"
                            onChange={toggleCheck}
                            checked={check}
                        />
                        <label className="login-label login-trust" htmlFor="persist">Trust This Device</label>
                    </div>
                </form>
                <div className='login-questions'>
                    Need an Account?<br />

                    <Link className="sign-link" to="/register">Sign Up</Link>

                </div>
                <div className='login-questions'>
                    Forgot password?<br />

                    <Link className="sign-link" to="/resetpassword">Reset</Link>

                </div>
            </section>
        </div>

    )
}

export default Login
