import { useState } from "react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function FlagInapropiateMessage({ messageId, setMessageFlagged }) {

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const BACKEND = process.env.REACT_APP_API_URL;
  const axiosPrivate = useAxiosPrivate();

  const handleInappropiate = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.post(`${BACKEND}/rides/message/flag/${messageId}`, {},
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.data) {
        throw new Error("Failed to flag message");
      }

      setError("");
      setMessageFlagged(prev => !prev);
    }
    catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred while flagging the message.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button className="red-button small-button" onClick={handleInappropiate}><FontAwesomeIcon icon={faBan} /></button>
      {isLoading && <div className="loading"></div>}
      {error && <p>{error}</p>}
    </div>
  );
}
