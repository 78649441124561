//Hooks
import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import useAuth from "../hooks/useAuth"

//Util functions
import { activateUser } from './util_functions/user_functions/DeleteUser';
import { deactivateUser } from './util_functions/user_functions/DeleteUser';
import { deleteUser } from './util_functions/user_functions/DeleteUser';

const UsersAdmin = () => {
  const BACKEND = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { auth } = useAuth();
  const [showLargePicture, setShowLargePicture] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  const loggedInUser = auth;

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {

      try {
        if (!auth || Object.keys(auth).length === 0) {
          throw new Error("Login to access this area.");
        }

        const response = await axiosPrivate.get(`${BACKEND}/users/`, {
          params: {
            user: auth
          }
        });
        if (isMounted) {
          setUsers(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error)
          } else {
            setError(error.message)
          }
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [auth, refresh]);

  const handleDeactivate = async (user) => {
    await deactivateUser(user, loggedInUser, auth);
    setUsers(users.map(u => u.id === user.id ? { ...u, isactive: false } : u));
    setRefresh(prev => !prev)
  };

  const handleActivate = async (user) => {
    await activateUser(user, loggedInUser, auth);
    setUsers(users.map(u => u.id === user.id ? { ...u, isactive: true } : u));
    setRefresh(prev => !prev)
  };

  const handleDelete = async (user) => {
    await deleteUser(user, user.id, setUsers, loggedInUser, auth);
    setUsers(users.filter(u => u.id !== user.id));
  };

  const handleAdminToggle = async (user) => {
    const updatedUser = { ...user, isadmin: !user.isadmin };
    try {
      await axiosPrivate.patch(`${BACKEND}/users/${user.id}`, updatedUser, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setUsers(users.map(u => (u.id === user.id ? updatedUser : u)));
    } catch (error) {
      console.error('Failed to update user admin status', error);
    }
  };

  if (isLoading) {
    return <div className="loading"></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='users-all-container'>
      {users.length === 0 ? (
        <>
          <div className="users-title">Admin users</div>
          <div>No users available.</div>
        </>

      ) : (
        <div className='users-admin-inner-container'>
          <div className="users-title">Admin users</div>
          {auth.accessToken !== undefined && auth.isAdmin ? (
            <div>
              {users.map(user => {

                const isAdmin = user.isadmin

                return (


                  <div
                    className='users-admin-user'
                    key={user.id} >
                    {!user.isactive && <div className="users-inactive">User is inactive</div>}

                    <div className='user-admin-details'>
                    <div className='users-all-picture-container'
                        
                        >
                          <img onClick={() => setShowLargePicture(user.id)} className='users-all-picture' src={`${BACKEND}/profile_pictures/${user.id}/profile_picture.jpg`}  />
                        </div>


                        {showLargePicture === user.id && <div
                        className='large-picture'
                        onClick={() => setShowLargePicture(null)}
                        >
                         <img 
                         className='users-all-picture-large'
                         onClick={() => setShowLargePicture(null)}
                         src={`${BACKEND}/profile_pictures/${user.id}/profile_picture.jpg`}  
                         onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop in case of repeated error
                          e.target.src = `${BACKEND}/profile_pictures/user.jpg`; // Default fallback image URL
                        }}/>
                          </div>}
                      <div className='users-admin-name'>Name: {user.username}</div>
                      <div className='users-admin-name'>Email: {user.email}</div>
                    </div>
                    <div className='user-actions'>
                      {loggedInUser.isSuperAdmin && !user.issuperadmin && user.isactive && <button onClick={() => handleDeactivate(user)}>Inactivate</button>}
                      {loggedInUser.isSuperAdmin && !user.issuperadmin && !user.isactive && <button
                        onClick={() => handleActivate(user)}
                      >Activate</button>}
                      {loggedInUser.isSuperAdmin && !user.issuperadmin && <button className="red-button" onClick={() => handleDelete(user)}>Delete</button>}
                   
                    {loggedInUser.isSuperAdmin && !user.issuperadmin &&
                      <div className='user-admin-admin'>  Admin    <input
                        type="checkbox"
                        id="permissionsCheckbox"
                        checked={user.isadmin || false}
                        onChange={() => handleAdminToggle(user)}
                      /></div>

                    }
                     </div>
                  </div>
                );
              })}


            </div>
          ) : (
            <p>Please log in as an administrator to see users.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default UsersAdmin;
