import React, { useState, useEffect, useMemo } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import LocationMarker from './util_functions/LocationMarker.jsx'
import greencircle from '../components/img/greencircle.png'
import recyclingBin from '../components/img/delete.png'
import undo from '../components/img/undo.png'
import AddMarker from "./AddMarker";
import useAuth from "../hooks/useAuth"

import '../styles/DrawMap.css'

import { useCoords } from '../components/util_functions/GetBrowserLocation';

//Util functions

import { removeUsersFromMap } from "./util_functions/map_functions/map_functions.jsx";
import { deactivateMap } from "./util_functions/map_functions/map_functions.jsx";

L.Marker.prototype.options.icon = L.icon({
  // iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  // iconUrl: require('../components/img/black-square.png'),
  iconUrl: greencircle,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  // popupAnchor: true,
  // shadowUrl: true,
  // shadowSize: true,
  // shadowAnchor: true
});

const group = L.featureGroup();


function Bounds({ coordinadasPara, defaultBounds }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    group.clearLayers();

    if (coordinadasPara && coordinadasPara.length > 0) {
      coordinadasPara.forEach((marker) => group.addLayer(L.marker(marker)));
      map.fitBounds(group.getBounds());
    } else {
      defaultBounds.forEach((marker) => group.addLayer(L.marker(marker)));
      map.fitBounds(group.getBounds());
    }

  }, [map, coordinadasPara, defaultBounds]);

  return null;
}

export default function DrawMap({ maps, setMaps, mapId, setMapId, editAllowed, setFake, fromButton, users }) {

  const BACKEND = process.env.REACT_APP_API_URL;
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  // const isSuperAdmin = auth.isSuperAdmin;
  const { browCoords } = useCoords();

  useEffect(() => {
    const foundMap = maps.find(obj => obj.id === parseInt(mapId));
    if (foundMap) {
      setMapId(foundMap.id);
    }
  }, [maps, mapId, setMapId]);


  const [points, setPoints] = useState();
  const [loading, setLoading] = useState(false);
  const [coordinadasPara, setCoordinadasPara] = useState([]);
  const [coord, setCoord] = useState([]);
  //State used to refresh when a point is added or removed, so the connecting line adjusts to the new route.
  const [removePoint, setRemovePoint] = useState(0)

  const [confirmDelete, setConfirmDelete] = useState(false)

  const defaultPosition = useMemo(() => {
    // Initialize your default position here
    return browCoords || [59.2827, -123.1207]
  }, [browCoords]);

  const [coordinatesForPolyline, setCoordinatesForPolyline] = useState([]);

  const userId = auth.userId;

  const isMapCreatedByUser = maps.find(map => map.id === mapId && map.createdby === auth.userId) !== undefined;

  // Sets the points of the map when a map is loaded
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(`${BACKEND}/points/${mapId}`);
        setPoints(response.data);
        setLoading(true);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [mapId, BACKEND]);

  useEffect(() => {

  }, [points, loading, coordinatesForPolyline, BACKEND]);

  //Object with two key/value pairs (array). Markers holds the default position, data will include eventually the new markers added
  const [markersState, setMarkersState] = useState({
    markers: [defaultPosition],
    data: []
  })


  //Fetches points from map. Transforms them (Array of objects with value/key pair) to array with two strings (format for bounds), sets CoordinadasPara with these arrays.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(`${BACKEND}/points/${mapId}`);

        if (response.data.length === 0) {
          // If coordinates are empty, set coordinadasPara to defaultPosition.
          setCoordinadasPara([[
            String(defaultPosition[0]),
            String(defaultPosition[1])
          ]]);
        } else {
          const coordinates = response.data.map(coordinadas => [
            String(coordinadas.lat),
            String(coordinadas.lng)
          ]);

          setCoordinadasPara(coordinates);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };
    fetchData();
  }, [mapId, defaultPosition, BACKEND]);

  //Fetches points from map. Transforms them (Array of objects with value/key pair) to array with two strings (format for bounds), sets coordinatesForPolyline with these arrays.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(`${BACKEND}/points/${mapId}`);

        const coordinates = response.data.map(coordinadas => [
          String(coordinadas.lat),
          String(coordinadas.lng)
        ]);

        setCoordinatesForPolyline(coordinates);
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };
    fetchData();
  }, [mapId,
    removePoint,
    coord,
    markersState.data,
    BACKEND
  ]);

  //Add new markers to the local state, update the state with the new data, prepare to send the data to the server (body), send the post request (axios). 

  const saveMarkers = (newMarkerCoords) => {

    const data = [...markersState.data, newMarkerCoords];
    // console.log("data", data)
    setMarkersState((prevState) => ({ ...prevState, data }));

    const body = {
      coords: Object.values(newMarkerCoords),
      mapId
    }

    axiosPrivate.post(`${BACKEND}/points`, body)
      .then((response) => {
      })

  };

  // Remove one marker
  const removeMarker = async () => {
    const updatedCoord = coord.slice(0, -1);
    await axiosPrivate.post(`${BACKEND}/points/delete/`, coord.slice(-1)[0]);
    setCoord(updatedCoord);
  };

  // Remove all markers
  const removeAll = async () => {
    await axiosPrivate.post(`${BACKEND}/points/delete/all/${mapId}`);
    setCoord([]);
  };

  // Remove last marker added
  const deleteLast = async (e) => {
    e.preventDefault();
    await removeMarker();
  };

  // Remove all markers

  const deleteAll = async (e) => {
    e.preventDefault();
    await removeAll();
  };


  const handleConfirmDelete = () => {
    setConfirmDelete(prev => !prev)
  }


  return (

    <div className="map-outer-container">
      <>

        <div className="manage-map-buttons">
          {
            !editAllowed ? (
              <div className="all-maps-text">
              </div>
            ) : (
              fromButton ?
                <div className="all-maps-text">Add, edit or remove markers</div> :
                <div className="all-maps-text">STEP 2: Add, edit or remove markers</div>
            )
          }

          {
            editAllowed &&
            <div className="deletebuttons">

              <img
                className="recbin"
                src={undo}
                alt={"Undo"}
                onClick={deleteLast}
              />

              <img
                className="recbin"
                src={recyclingBin}
                alt={"Recycling bin"}
                onClick={deleteAll}
              />

            </div>
          }

        </div>

        <MapContainer zoom={12}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <AddMarker
            maps={maps}
            saveMarkers={saveMarkers}
            setRemovePoint={setRemovePoint}
            coord={coord}
            setCoord={setCoord}
            mapId={mapId}
            defaultPosition={defaultPosition}
            editAllowed={editAllowed}
          />
          {/* Sends defaultBounds and coordinadasPara to the Bounds function, that gets the southwestermost and northeasternmost points to set the bounds of the map */}
          {coordinadasPara.length > 1 ? (
            <Bounds coordinadasPara={coordinadasPara} />
          ) : (
            coordinadasPara.length === 1 && (
              <Bounds coordinadasPara={coordinadasPara} />
            )
          )}

          <LocationMarker />

        </MapContainer>



        {maps.length && editAllowed ? (
          confirmDelete ? (
            <div className="maps-delete-buttons-container">
              <button
                className="delete-map-button"
                onClick={() => deactivateMap(mapId, userId, isMapCreatedByUser, maps, setMaps, setFake, setConfirmDelete, auth)}>Confirm delete</button>
              <button className="delete-map-button-close"
                onClick={handleConfirmDelete}
              >x</button>
            </div>
          ) : (
            <div className="maps-delete-buttons-container">
              <button
                className="delete-map-button"
                onClick={handleConfirmDelete}>Delete map</button>
            </div>
          )
        ) : (
          confirmDelete ? (
            <div className="maps-delete-buttons-container">
              <button
                className="delete-map-button"
                onClick={() => removeUsersFromMap(userId, mapId, setFake, setMaps, auth)}>Confirm</button>
              <button className="delete-map-button-close"
                onClick={handleConfirmDelete}
              >x</button>
            </div>
          ) : (
            <div className="maps-delete-buttons-container">
              <button
                className="delete-map-button"
                onClick={handleConfirmDelete}>Remove from my maps</button>
            </div>
          )
        )}






      </>
    </div>


  );
}
